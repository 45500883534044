.form {
  & > h4 {
    margin: 5px;
    text-align: center; } }

.center {
  display: flex;
  justify-content: center;
  margin: 5px; }

.customizationWrapper {
  padding: 0 50px;
  background-color: white;
  min-height: 50vh; }

.tabTitle {
  margin-bottom: 30px; }
