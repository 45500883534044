@import "assets/styles/global.sass";

.successStep {
    text-align: center;
    padding-top: 100px;
    color: $success-color; }

.icon {
    padding: 40px 0;
    font-size: 160px;
    color: $success-color; }

.button {
    margin: 0 15px; }
