@import "src/assets/styles/global";
.outlined {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid $text-color-secondary;
  margin-bottom: -8px !important;
  margin-right: 20px; }

.contained {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: $text-color-secondary;
  margin-bottom: -8px !important;
  margin-right: 20px; }

.wrapper {
  margin: 0 0 28px; }

.title {
  margin-bottom: 5px;
  font-size: 14px;
  color: $text-color-secondary; }
