.info {
  font-size: 16px;
  font-weight: 500;
  div {
    margin-bottom: 10px; } }

.rate:global.ant-rate {
  font-size: 25px !important;
  line-height: 1;
  margin-left: auto;
  display: block;
  width: 190px; }

.rate:global.ant-rate .ant-rate-star:not(:last-child) {
  margin-right: 15px !important; }

.rate:global.ant-rate .ant-rate-star-full,
.rate:global.ant-rate .ant-rate-star-first,
.rate:global.ant-rate .ant-rate-star-second {
  color: #1069fb !important; }
