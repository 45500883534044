.well {
    background-color:white !important {} }

.je-object__container {
    margin-top: 15px !important; }

.json-editor-btntype-properties {
    margin-left: 15px !important; }

.property-selector-input {
    margin: 5px; }

.json-editor-btntype-add {
    margin: 5px;
    margin-left: 10px; }

.json-editor-btntype-deletelast {
    margin: 5px; }

.json-editor-btntype-deleteall {
    margin: 5px; }
