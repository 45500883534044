@import 'src/assets/styles/global.sass';

.wrapper {
  position: relative; }

.colorSwatch {
  width: 36px;
  height: 30px;
  position: relative;
  border: 1px solid $text-color; }

.pickerContainer {
  position: absolute;
  z-index: 2;
  margin-top: 15px; }

.pickerWrapper {
  width: 230px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  background-color: $border-color-base;
  padding: 15px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.12), -1px -1px rgba(0, 0, 0, 0.08);
  & > *:not(:first-child) {
    margin-top: 15px; } }


.saturationWrapper {
  position: relative;
  height: 150px; }

.hueWrapper {
  position: relative;
  height: 12px;
  padding: 2px 0; }

.huePointer {
  height: 16px;
  width: 8px;
  background-color: $border-color-base;
  border: 1px solid $text-color;
  margin-top: -2px; }

.saturationPointer {
  width: 13px;
  height: 13px;
  box-shadow: 0 0 0 3px $border-color-base, inset 0 0 1px 1px $text-color;
  border-radius: 50%;
  cursor: hand; }

.editableInputWrapper {
  border: 1px solid $text-color;
  height: 44px;
  position: relative;
  margin-top: 15px;
  overflow: hidden;
  padding-left: 44px;
  input {
    border: none;
    height: 44px;
    padding-left: 5px;
    &:focus {
      border: none;
      outline: none; } } }

.editableInputSwatch {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 100%;
    box-shadow: 2px 0 1px 0 $text-color; }

.swatchesWrapper {
  display: flex;
  flex-wrap: wrap; }

.swatchWrapper {
  border: 1px solid  $text-color;
  height: 44px;
  overflow: hidden;
  width: 50px;
  margin: 5px 9px 0 0;
  &:nth-child(4n) {
    margin-right: 0; } }
