.button {

  margin: 15px; }

.containerOuter {
  position: absolute;
  right: 1%;
  top: 2%;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.containerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 16vw; }
