
.profileWrapper {
  width: 600px;
  margin: 0 auto;

  .link {
    font-family: 'dm_sansmedium', sans-serif!important;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: #40a9ff; } } }

.editProfile {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    padding-bottom: 0;
    margin-bottom: 0; }
  .link {
    font-size: 14px;
    font-family: 'dm_sansmedium', sans-serif; } }

.container {
  padding: 15px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box; }

.cardUser {
  padding: 30px 20px;
  width: 100%;
  border-radius: 8px; }

.userInfo {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.userInfoName {
  margin-top: 24px;
  margin-bottom: 10px;
  color: #2c3440;
  font-size: 24px;
  font-family: 'dm_sansbold', sans-serif; }

.userDescription {
  font-size: 16px;
  color: #979ca3;
  font-family: 'dm_sansmedium', sans-serif; }

.members {
  padding-top: 20px;
  border-top: 1px solid #f3f6f8;
  border-bottom: 1px solid #f3f6f8; }

.membersWrap {
  display: flex;
  justify-content: space-between; }

.membersTitle {
  margin-bottom: 15px;
  font-family: 'dm_sansregular', sans-serif;
  color: #979CA3; }

.membersList {
  list-style: none;
  padding-left: 0; }

.membersListEl {
  position: relative;
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-family: 'dm_sansregular', sans-serif; }

.membersListName {
  margin-left: 16px; }

.inviteLink {
  margin-top: 24px; }

.inviteLinkText {
  display: block;
  margin-bottom: 12px;
  color: #2C3440;
  font-family: 'dm_sansbold', sans-serif; }

.inviteErrorMessage {
  margin-top: 10px;

  text-align: center; }

.uploadBtn {
  background-color: #E8F0FE !important;
  color: #1C6EF7 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px;
  font-family: 'dm_sansbold', sans-serif;
  font-size: 14px;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-shadow: none; }

.deleteBtn {
  margin-left: 20px; }

.imageContainer {
  position: relative;
  height: 160px;
  margin-bottom: 10px;
  margin: 0px auto; }

.imageContainer {
  position: relative; }

.organizationLogo {
  border-radius: 10px;
  box-shadow: 1px 1px 5px grey; }

.sliderLogo {
    width: 100%;
    margin: auto;
    position: relative;
    padding: 0px 30px;
    display: flex;
    justify-content: space-evenly; }
