@import "assets/styles/global.sass";

.editorWrapper {
  display: flex;
  flex-direction: column; }

.textAreaWrapper {
  border: 1px solid $border-color-base;
  min-height: 100px; }

.numberInput {
  width: 100% !important; }
