.tipsWrapper {
  margin: 26px 0;
  text-align: center;
  font-size: 1rem; }

.codeWrapper {
  margin: 16px 5px 16px;
  font-size: 1rem; }

* :global .CodeMirror {
  height: 100%;
  min-height: 50vh; }

* :global .CodeMirror span {
   white-space: break-spaces;
   word-break: break-word; }

.templateTitleWrapper {
  text-align: center;
  margin: 36px 0; }

.previewWrapper {
  margin: 20px; }

.buttonWrapper {
  margin: auto; }

.submitBtn {
  position: fixed!important;
  bottom: 5%;
  right: 55vh;
  z-index: 100; }

* :global .CodeMirror-line {
  span[role=presentation] {
    padding-right: 100px !important; } }
