@import '~normalize.css/normalize.css';
// TODO: replace with https://ant.design/docs/react/getting-started#Import-on-Demand
@import '~antd/dist/antd.css';

#root {
  height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%; }

aside .ant-menu, .ant-menu-submenu-popup {
	background-color: #131630!important; }

.ant-menu-submenu-popup .ant-menu {
  background-color: #131630!important; }

aside .ant-menu {
   border: 0!important; }

aside .ant-menu, .ant-menu-submenu-popup {

	a {
		color: #A1A7BB!important; } }

aside .ant-menu-item-selected {
  background-color: #282C42!important;
  &:after {
    border-right: 0!important; } }


$primary-color: #1890ff; // primary color for all components
$link-color: #1890ff; // link color
$success-color: #52c41a; // success state color
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color
$font-size-base: 14px; // major text font size
$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: rgba(0, 0, 0, 0.65); // major text color
$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 2px; // major border radius
$border-color-base: #d9d9d9; // major border color
$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
