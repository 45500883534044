@import "src/assets/styles/global";
.previewText {
  margin-top: 10px;
  font-size: 16px; }

.title {
  margin-bottom: 5px;
  font-size: 14px;
  color: $text-color-secondary; }

.wrapper {
  margin: 0 0 20px; }
