@import "src/assets/styles/global";
.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  z-index: 10; }

.sidebarTrigger {
  font-size: 18px;
  line-height: 50px;
  cursor: pointer;
  transition: color 0.3s; }

.userMenu {
  width: 200px; }

.logo {
  max-height: 25px;
  object-fit: contain;
  justify-self: flex-start;
  margin-left: 24px; }

.rightBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.leftBar {
  display: flex;
  flex-direction: row; }

.nameWrapper {
  margin-right: 16px;
  color: $text-color;
  padding-top: 8px; }


.layout {
  display: flex;
  height: 90vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 8rem;
    color: $primary-color; }
  h2 {
    font-size: 1.5rem;
    color: $text-color; } }

.button {
  margin: 20px;
  width: 150px; }
