@import "src/assets/styles/global";
.icon {
  margin: 0 10px;
  font-size: 20px;
  color: $primary-color !important; }


::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important; }


::-webkit-scrollbar-track {
  border-radius: 10px !important; }


::-webkit-scrollbar-thumb {
  background: lightgray !important;
  border-radius: 10px !important; }

.logo {
  max-width: 150px;
  margin: 0 0 15px 0; }

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
