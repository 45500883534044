.center {
  display: flex;
  justify-content: center;
  align-items: center; }

.button {
  width: 220px;
  max-width: 220px;
  margin: 0 0 15px 0; }

.landingBlock {
  background-color: #f0f2f5;
  padding: 15px 0;
  margin: 15px;
  border-radius: 10px; }

.subtitle {
  font-size: 1rem;
  margin: 10px; }

.deleteBtn {
  position: absolute;
  right: 8px; }
