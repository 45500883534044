.containerOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16rem; }

.containerInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 500px; }

.button {
  min-width: 130px;
  margin: 15px; }
