.listActionBar {
  justify-content: right;
  text-align: right; }

.listActionBar div {
  margin: 0 0 10px;

  &:global.ant-select-selector {
    margin-bottom: 0; } }

.contentWrapper {
  padding: 20px;
  background: #fff;
  min-height: 500px; }

.sidebar {
  border-right: 1px solid #f0f0f0;
  padding: 20px;
  height: 100%; }

.btn {
  margin: 10px 20px;
  min-width: 240px; }

.deleteBtn {
  margin: 10px 20px;
  min-width: 150px; }

.deleteBtnWrapper {
  display: flex;
  flex-direction: column; }

.pagination {
  margin: 1rem;
  float: right;
  padding-bottom: 20px; }

.backButton {
  width: 15px;
  margin-left: -14px !important; }

.container {
  .loader {
    display: inline-block;
    min-height: auto;
    width: auto;
    margin-left: 10px; } }
.button {
  min-width: 140px;
  margin: 12px 8px; }

.containerOuter {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 100%; }

.containerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 24vw; }

.containerOuterRow {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 100%; }

.containerInnerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 24vw; }

.legacy {
  position: absolute;
  right: 5.5%;
  top: 15%;
  z-index: 20; }
.row {
  position: relative; }
