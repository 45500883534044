@import 'src/assets/styles/global.sass';
.themePalette {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px; }

.colorSectionTable {
  display: block; }

.colorSectionRow {
  display: flex;
  flex-flow: row wrap; }

.colorSectionCell {
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: center;
  padding: 15px;
  &:first-child {
    width: 100px;
    justify-content: flex-end; } }

.check {
  width: 20px;
  color: $text-color; }

.cross {
  width: 18px;
  color:  $error-color; }


.title {
  margin-bottom: 5px;
  font-size: 14px;
  color: $text-color-secondary; }
