.title {
  margin: 16px 0 24px !important; }

.button {
  min-width: 220px; }

.textWrapper {
  width: 150px;
  margin-top: 10px;
  margin-right: 10px; }

.blockWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid lightgray;
  justify-content: center; }

.containerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  margin-bottom: 10px; }

.break {
  margin-bottom: 30px; }

.containerOuter {
  margin-top: 20px;
  display: flex;
  flex-direction: column; }

.requestButton {
  width: 100%;
  margin: 20px; }

.rejectButton {
  width: 300px;
  margin-top: 10px;
  button {
    width: 300px; } }
