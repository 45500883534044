@import "assets/styles/global.sass";

.listActionBar {
  justify-content: right;
  text-align: right; }

.listActionBar div {
  margin: 20px 0; }

.contentWrapper {
  padding: 20px;
  background: #fff;
  min-height: 500px; }

.sidebar {
  border-right: 1px solid #f0f0f0;
  padding: 20px; }

.successStep {
  text-align: center;
  padding-top: 100px;
  color: $success-color; }


.icon {
  padding: 40px 0;
  font-size: 160px;
  color: $success-color; }

.textCenter {
  text-align: center; }

.entitySelector {
  text-align: center;
  margin: 10px 0 100px 0; }

.autofillBtn {
  margin-top: 10px; }

.hr {
  margin-bottom: 20px; }

.selectBtn {
  width: 145px;
  margin: 15px 0; }

.description {
  margin: 20px; }

.section {
  margin: 20px 0; }

.bottomButtonsWrapper {
  margin: 20px auto 30px;
  padding-bottom: 30px; }

.bottomButton {
  margin: 0 20px; }

.centered {
  display: flex;
  justify-content: center;
  margin: 10px; }

