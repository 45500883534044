.title {
  margin: auto !important; }


.button {
  min-width: 16vw;
  margin: 15px; }

.blockWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid lightgray;
  justify-content: center; }

.rejectButton {
  min-width: 16vw;
  margin: 10px auto; }

.containerOuter {
  margin-top: 14vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.containerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 16vw; }
