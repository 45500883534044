.table {
  width: 100%; }

.criteriaColumn {
  width: 150px; }

 :global {
  .ant-form-item-label {
    text-align: left!important; } }

.dropDownCustom {
  width: 300px; }
